import React, {useState, memo, useRef, useEffect} from "react";
import {StackedCarousel, ResponsiveContainer} from "react-stacked-center-carousel";
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/outline';
import axios from 'axios';
import TwitchThumbnailRef from "./twitch/TwitchThumbnailRef";

export const Slider = () => {
    const ref = useRef(StackedCarousel);

    const [livestreams, setLivestreams] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios.get('/streams/', { params: { limit: 5 }}).then((res) => {
            const livestreamsLength = res.data.length;
            if (livestreamsLength < 5) {
                for (let i = 0; i < (5 - livestreamsLength); i++) {
                    res.data.push({ stream: { user_name: "placeholder" } });
                }
            }
            setLivestreams(res.data);
            setLoaded(true);
        }).catch(e => console.log(e));
    }, []);

    return (
        loaded ?
            <div>
                <ResponsiveContainer
                    carouselRef={ref}
                    render={(width, carouselRef) => {
                        return (
                            <StackedCarousel
                                className="!overflow-visible"
                                ref={carouselRef}
                                slideComponent={Slide}
                                slideWidth={530}
                                carouselWidth={width}
                                data={livestreams}
                                maxVisibleSlide={5}
                                disableSwipe
                                customScales={[1, 0.85, 0.7, 0.55]}
                                transitionTime={450}
                            />
                        );
                    }}
                />
                <ChevronLeftIcon
                    className='absolute top-1/2 translate-y-[-50%] w-6 h-6 z-10	cursor-pointer text-gray-100 transition-default hover:text-white select-none'
                    onClick={() => ref.current?.goBack()}
                />
                <ChevronRightIcon
                    className='absolute top-1/2 translate-y-[-50%] w-6 h-6 z-10	cursor-pointer text-gray-100 transition-default hover:text-white select-none right-0'
                    onClick={() => ref.current?.goNext()}
                />
            </div>
            : null
    );
}

const Slide = memo(function (StackedCarouselSlideProps) {
    const {data, dataIndex, isCenterSlide, swipeTo, slideIndex} = StackedCarouselSlideProps;

    const dataIdx = data[dataIndex].stream;
    const user_name = dataIdx?.user_name ? dataIdx.user_name : "placeholder";

    const CoverImage = () => (
        <img className='object-cover w-full h-full rounded-[inherit]' src={user_name === 'placeholder' ? '/stream.svg' : `/thumbnails/${coverImage}.png`}/>
    );

    return (
        <div
            className='card group rounded-[inherit] transition-default cursor-pointer w-full h-[300px] relative hover:scale-105'
            draggable={false}>
            {
                <div className="cover absolute rounded-[inherit] transition-default w-full h-full">
                    {
                        user_name === "placeholder" ?
                            <div
                                className='card-overlay absolute select-none w-full h-full transition-default bg-gray-900/50 group-hover:bg-gray-900/20'
                                onClick={() => {
                                    if (!isCenterSlide) swipeTo(slideIndex);
                                }}
                            >
                                <CoverImage/>
                            </div>
                        :
                            <div
                                className="rounded-[inherit] group relative overflow-hidden card-overlay absolute select-none w-full h-full transition-default bg-gray-900/50 group-hover:bg-gray-900/20"
                                onClick={() => {
                                    if (!isCenterSlide) swipeTo(slideIndex);
                                }}
                            >
                                <TwitchThumbnailRef className="object-cover w-full h-full rounded-[inherit]" channelData={dataIdx} clickable={isCenterSlide}/>
                            </div>
                    }
                </div>
            }
        </div>
    );
});
