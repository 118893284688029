import React, {Component, useEffect, useRef, useState} from 'react';
import {Slider} from "../components/Slider";
import {Games} from "../components/Games";
import {Challenges} from "../components/Challenges";
import {request} from "graphql-request";
import config from "../../server/config/default";
import {withRouter} from 'react-router-dom';
import axios from "axios";
import moment from "moment";
import TwitchThumbnailRef from "../components/twitch/TwitchThumbnailRef";

export const Homepage = () => {
    const [challenges, setChallenges] = useState([]);
    const [featureDescription, setFeatureDescription] = useState("");

    const isChallengeInPast = (challenge) => {
        let endDate = moment(challenge.endTime);
        let currentDate = moment();
        return currentDate.isAfter(endDate);
    }

    useEffect(() => {
        request(config.content_api, `{ namings {featuredDescription} }`).then(res => {
            setFeatureDescription(res.namings[0].featuredDescription)
        }).catch(e => console.log("livestreams component mount", e))

        axios.get("/dashboard/challenges").then(res => {
            setChallenges(res.data.filter(c => !isChallengeInPast(c)));
        }).catch(e => console.log("challenges", e));
    }, [])

    return (
        <div className="overflow-hidden">
            <div className="relative bg-gradient-to-b from-gray-850 to-gray-850">
                <div className="absolute inset-0 bg-bottom bg-no-repeat pointer-events-none">
                    <div className="absolute inset-0 bg-[url('grid.svg')] bg-bottom opacity-40"
                         style={{
                             maskImage: "linear-gradient(to bottom, transparent, black)",
                             WebkitMaskImage: "linear-gradient(to bottom, transparent, black)"
                         }}></div>
                </div>

                <div className="container-primary pt-32 sm:pt-44">
                    <div className="container-primary-content">
                        <h1 className="headline">Featured</h1>
                        <h2 className="subline">{featureDescription}</h2>
                        <div className="relative w-full mt-10 sm:mt-16 mb-16 sm:mb-24">
                            <Slider/>
                        </div>
                    </div>
                </div>
            </div>
            {
                challenges.length > 0 ?
                    <div className="container-primary border-t border-gray-750/50 bg-gradient-to-b from-gray-850 to-gray-800">
                        <div className="container-primary-content py-16">
                            <Challenges challenges={challenges}/>
                            <p className="mt-16 text-base text-white">
                                You have questions how to join the challenges? Then check out our&nbsp;
                                <a className="underline" href="/faq">FAQ</a>.
                            </p>
                        </div>
                    </div>
                : <></>
            }
            <div
                className="container-primary border-t border-gray-750/50 bg-gradient-to-b from-gray-850 to-gray-800">
                {
                    challenges.length > 0 ?
                        <div className="absolute inset-0 bg-[url('grid.svg')] h-56 bg-top opacity-40"
                             style={{
                                 maskImage: "linear-gradient(to top, transparent, black)",
                                 WebkitMaskImage: "linear-gradient(to top, transparent, black)"
                             }}></div>
                        : <></>
                }
                <div className="container-primary-content py-20">
                    <h1 className="headline !text-left sm:!text-center mb-12">Game Categories</h1>
                    <Games/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Homepage);
