import React, {useState, useEffect} from "react";
import {request} from "graphql-request";
import config from "../../server/config/default";

const FAQ = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        request(config.content_api, `{ legal(where: {name: "FAQ"}) {content{html}} }`).then(res => {
            setContent(res.legal.content.html)
        })
    }, []);

    return (
        <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                <div className="relative w-full mt-10 mb-20 sm:mt-16 rich-text" dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
        </div>
    );
}

export default FAQ;
