import React, {useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../../server/config/default";
import {request} from "graphql-request";

const GameCategory = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(12);
    const [livestreams, setLivestreams] = useState([]);
    const [boxArt, setBoxArt] = useState(null);

    const game = decodeURIComponent(props.match.params.game);

    const loadLivestreams = async () => {
        const result = await axios.get('/streams/game', { params: { limit: 30, gameName: game } });
        setLivestreams(result.data);
    }

    const loadBoxarts = async () => {
        const result = await request(config.content_api, `{ games(where: {name: "${game}"}) {games} }`);
        setBoxArt(result.games[0].games.data[0].box_art_url.replace('{width}x{height}', '570x760'));
    }

    useEffect(() => {
        const fetchInfo = async () => {
            await loadLivestreams();
            await loadBoxarts();

            setLoaded(true);
        }

        fetchInfo().catch(console.error);2
    }, []);

    return (
        <React.Fragment>
            <div className="container-primary pt-32 sm:pt-44 text-gray-100">
                <img className="object-cover absolute inset-0 blur w-full h-[350px] opacity-40" src={boxArt} style={{
                    maskImage: "linear-gradient(to bottom, transparent, black)",
                    WebkitMaskImage: "linear-gradient(to bottom, transparent, black)"
                }} />
                <div className="bg-gradient-to-t from-gray-850 to-transparent absolute inset-0 w-full h-[350px]"></div>
                <div className="container-primary-content mb-20">
                    <h1 className="headline z-10 mb-10">{game}</h1>
                    <div className="relative z-10 w-full flex flex-wrap gap-6 -mb-6 justify-center">
                        {
                            loaded ?
                                livestreams.slice(0, visible).map((entry, index) =>
                                    <a key={index} href={`/stream/${entry.stream.user_name}`} className="flex-auto sm:flex-[1_0_33%] lg:flex-[1_0_24%] xl:flex-[1_0_19%] box transition-default hover:scale-105">
                                        <img className='rounded-t-[inherit]' src={entry.stream.thumbnail_url.replace("{width}", "640").replace("{height}", "360")}/>
                                        <div className="flex items-center p-2">
                                            <img className="h-8	w-8 mr-2 rounded-full"
                                                 src={`/profile_pictures/${entry.account.username}.png`}
                                                 onError={(e) => {
                                                     e.target.src = '/profile_pictures/default.svg'
                                                 }}/>
                                            <p className="text-gray-200 text-sm">{entry.account.username}</p>
                                        </div>
                                    </a>
                                )
                            : null
                        }
                        {
                            livestreams.length === 0 ?
                                <div className="flex flex-col items-center">
                                    <h2 className="text-xl font-medium text-gray-200 md:text-2xl">No streams available ☹</h2>
                                    <a href="/">
                                        <button className="primary mt-10">Go back</button>
                                    </a>
                                </div>
                            : [...Array(5)].map((e, i) => <div key={i}
                            className="flex-auto sm:flex-[1_0_33%] lg:flex-[1_0_24%] xl:flex-[1_0_19%]"></div>) /* Placeholders */
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GameCategory;
