import React, {useState, useEffect} from "react";
import axios from "axios";
import {useForm} from "react-hook-form";
import {Redirect} from "react-router-dom";

const Poll = (props) => {
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState("");

    const loadPollQuestions = async () => {
        const poll_name = props.match.params.pollName;
        if (!poll_name)
            return;

        const response = await axios.get("/settings/poll", { params: { poll_name: poll_name } })
        if (!response.data) {
            setError("This poll doesn't exist :(");
            return;
        }

        setQuestions(response.data.questions);
    }

    const submitWithdrawTicket = async (data) => {
        const poll_name = props.match.params.pollName;
        if (!poll_name)
            return;

        const response = await axios.post("/settings/poll", { poll_name: poll_name, answers: data });
        if (!response)
            return;

        props.history.push('/')
        window.location.reload();
    }

    useEffect(() => {
        const fetchInfo = async () => {
            await loadPollQuestions();
        }

        fetchInfo().catch(console.error);
    }, []);

    const { register, handleSubmit } = useForm();
    return (
        <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                {
                    error !== "" ?
                        <h1 className="headline mt-12 sm:mt-24 mb-12 sm:mb-24">{error}</h1>
                    :
                        <div className="px-4 w-full max-w-md sm:px-8 py-10 shadow-lg bg-gray-750/30 border-gray-750/30 border rounded-md h-full mx-4 sm:mx-0 mt-12 sm:mt-24 mb-12 sm:mb-24">
                            <p className="mb-8 text-white text-center">
                                Enhance our service by sharing your insights through a brief poll about your experiences!
                            </p>

                            <form onSubmit={handleSubmit(submitWithdrawTicket)} method="POST" className="mb-4">
                                {
                                    questions.map((value, index) =>
                                        <div key={`question${index}`}>
                                            <label key={`question${index}_1`} htmlFor={`question${index}`} className="text-white">{value}</label>
                                            <input {...register(`question${index}`)} key={`question${index}_2`} type="text" name={`question${index}`} id={`question${index}`} className="mb-8" required/>
                                        </div>
                                    )
                                }
                                <button type="submit" className="flex items-center justify-center w-full h-10 font-bold primary">
                                        Send and continue
                                </button>
                            </form>
                        </div>
                }
            </div>
        </div>
    );
}

export default Poll;
