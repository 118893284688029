import React, {useState, useEffect, Fragment} from "react";
import {SearchIcon, ArrowRightIcon, XIcon} from '@heroicons/react/solid';
import axios from "axios"
import moment from "moment";
import Modal from "react-modal";
import {request} from "graphql-request";
import config from "../../server/config/default";

export const Challenges = (props) => {

    const [userInfo, setUserInfo] = useState(null);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [loaded, setLoaded] = useState(false);

    const [rulesModalOpen, setRulesModalOpen] = useState(false);
    const [idOfRules, setIdOfRules] = useState(0);
    const [games, setGames] = useState([]);

    const [interactionLoading, setInteractionLoading] = useState(false);


    useEffect(() => {
        Modal.setAppElement("#root")
        loadUserInfo();

        request(config.content_api, `{ abouts {description items{name description list{name}}} games(first: 15, orderBy: name_ASC) {games} }`).then(res => {
            setGames(res.games);
        })
    }, [])

    const loadUserInfo = () => {
        const fetchInfo = async () => {
            const userInfoRes = await axios.get('/user/info')
            if (typeof userInfoRes.data === "object") {
                setUserInfo(userInfoRes.data);
                setLoggedIn(true);
            }
            setLoaded(true);
        }

        fetchInfo().catch(console.error);
    }

    const isChallengeRunning = (challenge) => {
        let startDate = moment(challenge.startTime);
        let currentDate = moment();
        return currentDate.isAfter(startDate);
    }

    const dateRounder = (time) => {
        const now = new Date().getTime();
        const distance = time - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        let message = "";

        if (minutes > 0) {
            message = minutes + " minutes"
        }

        if (hours > 0) {
            message = hours + " hours"
        }

        if (days > 0) {
            message = days + " days"
        }

        return message;
    }

    const joinChallenge = (challenge) => {
        if (userInfo.activeChallenge) {
            return;
        }

        setInteractionLoading(true)
        axios.post('/user/challenge-join', { challenge: challenge.id })
            .then(res => {
                let error = res.data.error;
                setErrorMsg(error);
                if (!error) {
                    let updatedChallenge = { activeChallenge: challenge }
                    setUserInfo(info => ({ ... info, ...updatedChallenge}))
                }
                setInteractionLoading(false)
            }).catch(console.error);
    }

    const isBannedFrom = (challenge) => {
        return userInfo.bans.filter(ban => ban.id == challenge.id).length > 0;
    }

    const leaveChallenge = (challenge) => {
        if (userInfo.activeChallenge == -1) {
            return;
        }

        setInteractionLoading(true)
        axios.post('/user/challenge-leave')
            .then(res => {
                let status = res.status;
                if (status == 200) {
                    let updatedChallenge = { activeChallenge: null }
                    setUserInfo(info => ({ ... info, ...updatedChallenge}))
                }
                setInteractionLoading(false)
            })
            .catch(console.error);
    }

    const getChallengePrice = (challenge) => {
        if (!userInfo) {
            return challenge.joinPrice;
        }
        return userInfo.freeGames > 0 ? 0 : (1 - userInfo.discount) * challenge.joinPrice
    }

    const openRulesModal = (challengeIdx) => {
        setIdOfRules(challengeIdx);
        setRulesModalOpen(true);
    }

    const getChallengeBoxArt = (challenge) => {
        let gameData = challenge.game.games.data;
        if (gameData.length > 0) {
            return gameData[0].box_art_url.replace("{width}", 285).replace("{height}", 380);
        } else {
            return "https://static-cdn.jtvnw.net/ttv-boxart/497057-285x380.jpg";
        }
    }

    const closeRulesModal = () => {
        setRulesModalOpen(false);
    }

    const GameCardPlaceholder = () => (
        <div className="flex-[1_0_16%]">
            <div
                className="relative block shadow-md rounded-md bg-gray-750/50 border border-gray-750 pb-[133.333333%]"></div>
        </div>
    )
    return (
        loaded ?
            <div className="relative w-full">
                <div className="relative">
                    <div className="absolute z-[2] headline h-full flex justify-center items-center flex-col" style={{zIndex: 2}}>
                        <h1 className="headline">Challenges</h1>
                        <p className="mt-4 text-base font-medium text-gray-100">
                            Explore our active challenges and compete for cash rewards below.<br/>
                            Looking for what's next? Check out our <a href="/upcoming" className="text-indigo-500 cursor-pointer">Upcoming Challenges</a> page for exciting future opportunities. <br/>
                            Get ready to win big!
                        </p>
                    </div>
                    <div className="relative flex lg:mr-10 mb-10 items-center overflow-hidden h-[400px] after:absolute after:inset-0 after:content-[''] overlay-shadow blur-sm opacity-50" style={{filter: "blur(4px)", height: 400}}>
                        <div className="brightness-[.40] -rotate-6 relative w-full flex flex-wrap gap-5 [&_:nth-child(10n+6)]:translate-x-20 [&_:nth-child(10n+7)]:translate-x-20 [&_:nth-child(10n+8)]:translate-x-20 [&_:nth-child(10n+9)]:translate-x-20 [&_:nth-child(10n+10)]:translate-x-20" style={{ filter: "brightness(.40)" }}>
                            <GameCardPlaceholder/>
                            {
                                games.map((game, gameIndex) => (
                                    <Fragment key={gameIndex}>
                                        <div className="flex-[1_0_16%]">
                                            <div
                                                className="relative block shadow-md rounded-md pb-[133.333333%]">
                                                <img
                                                    src={game.games.data[0].box_art_url.replace('{width}x{height}', '285x380')}
                                                    className="absolute object-contain object-center w-full h-full rounded-[inherit]"/>
                                            </div>
                                        </div>
                                        {(gameIndex + 1) % 8 === 0 && (gameIndex + 1) !== games.length && [...Array(2)].map((e, i) =>
                                            <GameCardPlaceholder key={i}/>)
                                        }
                                    </Fragment>
                                ))
                            }
                            <GameCardPlaceholder/>
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 xl:gap-6">
                    {
                        props.challenges.filter(challenge => isChallengeRunning(challenge)).map((challenge, index) => (
                            <div key={index} className="flex h-full w-full flex-col items-start p-6 text-left box">
                                <div className="w-full flex items-center">
                                    <img src={getChallengeBoxArt(challenge)} className="h-14 rounded-sm shadow-sm w-auto mr-2 object-contain"/>
                                    <div>
                                        <h3 className="mb-auto text-lg font-bold text-white first:text-indigo-500 lg:text-base xl:text-lg">{challenge.challengeTitle}</h3>
                                        <p className="text-gray-100">🏆 {challenge.reward} {challenge.rewardType == "Coins" ? "Coins" : "€"} 🏆</p>
                                    </div>
                                    <p className="ml-auto text-sm font-normal text-gray-100 text-center !rounded-md box px-2.5 py-1.5">{getChallengePrice(challenge)} Coins Required</p>
                                </div>

                                <p className="text-left text-sm text-red-500/80 mt-0.5">{errorMsg}</p>
                                <p className="mt-2 mb-auto text-base text-gray-100 xl:text-[15px]">{challenge.description}</p>

                                <hr className="w-full border-t border-white/5 my-6"/>

                                <div className="w-full flex items-center justify-between mb-2">
                                    <p className="text-white">Difficulty:</p>
                                    <p className="text-gray-100">{challenge.difficulty}</p>
                                </div>

                                <div className="w-full flex items-center justify-between mb-2">
                                    <p className="text-white">Plattform:</p>
                                    <div className="flex gap-1.5">
                                        {
                                            challenge.platform.map(name => (
                                                <div key={name} className="text-sm font-normal text-gray-100 text-center border border-white/5 rounded-md px-2.5 py-1">
                                                    { name }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                {
                                    isChallengeRunning(challenge) ?
                                        <div className="w-full">
                                            <div className="w-full flex items-center justify-between">
                                                <p className="text-white">Ends in:</p>
                                                <div className="tooltip">
                                                    <span className="text-gray-100">{dateRounder(Date.now() + (new Date(challenge.endTime) - Date.now()))}</span>
                                                    <p>{moment(challenge.endTime).format('lll').toString()}</p>
                                                </div>
                                            </div>
                                            <div className="mt-6 sm:mt-8 w-full flex justify-end items-center">
                                                <button className="btn ghost mr-2" onClick={() => openRulesModal(index)}>
                                                    View Rules
                                                </button>
                                                <a className="btn ghost mr-2" href={`/challenge/${challenge.id}`}>
                                                    Spectate<SearchIcon className="text-white ml-2 h-5 w-5"/>
                                                </a>
                                                {
                                                    isLoggedIn ?
                                                        !userInfo.activeChallenge && !isBannedFrom(challenge) ?
                                                            <button className="primary" onClick={() => joinChallenge(challenge)}>
                                                                {
                                                                    interactionLoading ?
                                                                        <div className="flex items-center justify-center h-5 w-5 justify-end">
                                                                            <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-6 w-6"></div>
                                                                        </div>
                                                                    :
                                                                        <p className="flex flex-row">Join<ArrowRightIcon className="ml-2 h-5 w-5"/></p>
                                                                }
                                                            </button>
                                                        :
                                                            userInfo.activeChallenge?.id === challenge.id ?
                                                                <button className="primary !bg-red-500/80 focus:!ring-0" onClick={() => leaveChallenge(challenge)}>
                                                                    {
                                                                        interactionLoading ?
                                                                            <div className="flex items-center justify-center h-5 w-5 justify-end">
                                                                                <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-6 w-6"></div>
                                                                            </div>
                                                                        :
                                                                            <p className="flex flex-row">Leave <XIcon className="ml-2 h-5 w-5"/></p>
                                                                    }
                                                                </button>
                                                            :
                                                                <></>
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                    :
                                            <div className="w-full">

                                                <div className="w-full flex items-center justify-between">
                                                    <p className="text-white">Start countdown:</p>
                                                    <div className="tooltip">
                                                        <span className="text-gray-100">{dateRounder(Date.now() + (new Date(challenge.startTime) - Date.now()))}</span>
                                                        <p>{moment(new Date(challenge.startTime)).format('lll')}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-6 sm:mt-8 w-full flex justify-end items-center">
                                                    <button className="btn ghost mr-2" onClick={() => openRulesModal(index)}>
                                                        View Rules
                                                    </button>
                                                </div>
                                            </div>
                                }
                            </div>
                        ))
                    }

                    {
                        props.challenges.filter(challenge => isChallengeRunning(challenge)).length > 0 ?
                            <Modal
                                isOpen={rulesModalOpen}
                                onRequestClose={closeRulesModal}
                                contentLabel="Example Modal"
                                className="absolute bg-gray-750 rounded-lg z-40 top-1/2 left-1/2 right-auto bottom-auto mr-[-50%] translate-x-[-50%] translate-y-[-50%] w-[420px]"
                                style={{
                                    overlay: {
                                        backgroundColor: "rgb(19 22 31 / 0.7)",
                                        zIndex: 10
                                    }
                                }}
                            >
                                <div className="flex h-full w-full flex-col items-start p-6 text-left z-40">
                                    {
                                        props.challenges.filter(challenge => isChallengeRunning(challenge))[idOfRules].rules.length > 0 ?
                                            props.challenges.filter(challenge => isChallengeRunning(challenge))[idOfRules].rules.map((rule, index) => (
                                                <p className="text-white" key={index}>{index+1}. {rule.name}</p>
                                            ))
                                            :
                                            <p className="text-white">No Rules defined.</p>
                                    }
                                    <div className="flex justify-center items-center w-full mt-5">
                                        <button className="btn ghost mr-2" onClick={closeRulesModal}>Back</button>
                                    </div>
                                </div>
                            </Modal>
                        :
                            <></>
                    }
                </div>
            </div>
        :
            <></>
    );
}
