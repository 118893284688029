import React, {Fragment, useEffect, useState} from 'react'
import axios from 'axios';
import {request} from "graphql-request";
import config from "../../server/config/default";
import {Listbox, Transition, Switch} from '@headlessui/react';
import {ChevronUpIcon, ChevronDownIcon, CheckIcon, ClipboardCopyIcon, RefreshIcon} from '@heroicons/react/solid'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const Settings = (props) => {
    const [rtmpTooltip, setRtmpTooltip] = useState('Copy to clipboard');

    const [userInfo, setUserInfo] = useState({});
    const [games, setGames] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [settingsDescription, setSettingsDescription] = useState('');

    const [streamEnabled, setStreamEnabled] = useState(false);

    const [isGameChanging, setGameChanging] = useState(false);
    const [isSwitchStatus, setSwitchingStatus] = useState(false);

    const loadUserIsStreaming = async () => {
        const userIsStreamingResult = await axios.get("/user/is-streaming");
        setStreamEnabled(userIsStreamingResult.data.status);
    }

    const loadUserInfo = async () => {
        const userInfoResult = await axios.get("/user/info");
        console.log(userInfoResult.data)
        setUserInfo(userInfoResult.data);
    }

    const loadSettingsDescription = async () => {
        const settingsDescriptionResult = await request(config.content_api, `{ namings {settingsDescription} }`);
        setSettingsDescription(settingsDescriptionResult['namings'][0].settingsDescription)
    }

    const loadGames = async () => {
        const gamesResult = await request(config.content_api, `{ games(first: 100, orderBy: name_ASC) { id, name } }`)
        const remappedGames = Object.fromEntries(gamesResult.games.map(game => [game.id, game.name]));
        setGames(remappedGames)
    }

    useEffect(() => {
        const fetchInfo = async () => {
            await loadUserInfo();
            await loadGames();
            await loadSettingsDescription();
            await loadUserIsStreaming();
            setLoaded(true);
        }

        fetchInfo().catch(console.error);
    }, []);

    const setCurrentGame = async (id) => {
        setGameChanging(true)
        await axios.post('/settings/current_game', { game_id: id });
        setGameChanging(false)

        let updatedGame = { currentGame: { id: id, name: games[id] } }
        setUserInfo(info => ({ ... info, ...updatedGame}))
    }

    const handleFileChange = (event) => {
        console.log(event)
        event.preventDefault();

        const data = new FormData();
        data.append("file", event.target.files[0]);

        axios.post("/settings/profile_picture", data).then(response => window.location.reload());
    };

    const getRtmpServer = () => {
        setRtmpTooltip("Copied.");
    }

    const deleteAccount = async () => {
        await axios.post("/user/set-offline");
        await axios.post("/user/delete");

        props.history.push('/poll/DeletePoll');
    }

    const unlinkTwitch = async () => {
        await axios.post("/user/set-offline");
        await axios.post("/user/unlink");

        let updatedInfo = { twitchName: "" }
        setUserInfo(info => ({ ... info, ...updatedInfo}))
    }

    const updateOnlineStatus = async () => {
        setStreamEnabled(!streamEnabled)

        setSwitchingStatus(true);
        await axios.post(!streamEnabled ? "/user/set-online" : "/user/set-offline");
        setSwitchingStatus(false);
    }

    return (
        loaded ?
            <React.Fragment>
                <div className="container-primary pt-32 sm:pt-44 text-gray-100">
                    <div
                        className="container-primary-content">
                        <h1 className="headline z-10">Settings</h1>
                        <h2 className="subline z-10">{ settingsDescription }</h2>
                        <div
                            className="mt-10 sm:mt-16 mb-20 md:!grid md:gap-8 space-y-8 md:space-y-0 grid-cols-1 lg:grid-cols-2">
                            <div className="box z-10 p-10 lg:p-14 w-full h-full">
                                <h3 className="font-bold md:leading-tight text-white text-3xl sm:text-4xl mb-3">
                                    Account
                                </h3>

                                <div className="flex md:items-center md:flex-row flex-col mb-5 gap-y-2 md:gap-y-0">
                                    <img className="h-24 w-24 rounded-full mr-4"
                                         src={'/profile_pictures/' + userInfo.username + '.png'}
                                         onError={(e) => {
                                             e.target.src = '/profile_pictures/default.svg'
                                         }}/>
                                    <div className="flex">
                                        <label htmlFor="avatar" className="primary btn cursor-pointer">Change Avatar</label>
                                        <input type="file" id="avatar" name="file" accept="image/png" onChange={ handleFileChange }/>
                                    </div>
                                </div>

                                <div
                                    className="flex md:justify-between md:items-center md:flex-row flex-col gap-y-2 md:gap-y-0">
                                    <p className="text-white">Current Game:</p>

                                    <div className="flex flex-row justify-center items-center gap-5">
                                        {
                                            isGameChanging ?
                                                <div className="flex items-center justify-center h-5 w-5 justify-end">
                                                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-6 w-6"></div>
                                                </div>
                                            :
                                                null
                                        }

                                        <Listbox onChange={ setCurrentGame }>
                                            <div className="relative">
                                                <Listbox.Button
                                                    className="relative !rounded-md box !block text-left min-w-[250px]">
                                                    <span
                                                        className="block truncate">{ !userInfo.currentGame ? 'Select a game' : userInfo.currentGame.name }</span>
                                                    <span
                                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 flex flex-col h-full justify-center">
                                                        <ChevronUpIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                                        <ChevronDownIcon className="h-4 w-4 text-white" aria-hidden="true"/>
                                                    </span>
                                                </Listbox.Button>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Listbox.Options
                                                        className="absolute space-y-2 list-none mt-1 max-h-60 w-full overflow-auto py-2 text-base sm:text-sm focus:outline-0 rounded-md bg-gray-750 shadow-lg ring-1 ring-gray-700 z-10">
                                                        {Object.entries(games).map(([id, name]) => (
                                                            <Listbox.Option
                                                                key={id}
                                                                className={({active}) =>
                                                                    `relative cursor-pointer select-none transition-default mx-2 rounded-md ${
                                                                        active ? 'bg-gray-700 text-white' : 'text-gray-100'
                                                                    }`
                                                                }
                                                                value={id}
                                                            >
                                                                {({selected}) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate px-4 py-2 ${
                                                                                selected ? 'font-medium bg-indigo-600 text-white transition-default rounded-md' : 'font-normal'
                                                                            }`}
                                                                        >
                                                                            {name}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </Listbox>
                                    </div>
                                </div>

                                {
                                    userInfo.twitchName !== "" ?
                                        <div className="flex md:justify-end md:items-center md:flex-row flex-col gap-y-2 md:gap-y-0 mt-5">
                                            {
                                                streamEnabled ?
                                                    <button className="primary !bg-gray-700 focus:!ring-0" onClick={updateOnlineStatus}>
                                                        {
                                                            isSwitchStatus ?
                                                                <div className="flex items-center justify-center h-5 w-5 justify-end">
                                                                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-6 w-6"></div>
                                                                </div>
                                                            :
                                                                <p>Go Offline</p>
                                                        }
                                                    </button>
                                                :
                                                    <button className="primary bg-indigo-600 focus:!ring-0" onClick={updateOnlineStatus}>
                                                        {
                                                            isSwitchStatus ?
                                                                <div className="flex items-center justify-center h-5 w-5 justify-end">
                                                                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-6 w-6"></div>
                                                                </div>
                                                            :
                                                                <p>Go Live</p>
                                                        }
                                                    </button>
                                            }
                                        </div>
                                    :
                                        null
                                }

                                <hr className="border-t border-white/5 my-6"/>

                                <div className="flex justify-between">
                                    {
                                        userInfo.twitchName && userInfo.twitchName !== "" ?
                                            <button className="primary !bg-gray-700 focus:!ring-0" onClick={ unlinkTwitch }>
                                                Unlink Twitch Account
                                            </button>
                                        :
                                            <button className="primary !bg-[#9147ff] focus:!ring-0">
                                                <a href="/login/twitch">
                                                    Link Twitch Account
                                                </a>
                                            </button>
                                    }
                                    <button className="primary !bg-red-500/80 focus:!ring-0" onClick={ deleteAccount }>
                                        Delete Account
                                    </button>
                                </div>
                            </div>

                            <div className="box p-10 lg:p-14 w-full h-full">
                                <h3 className="font-bold md:leading-tight text-white text-3xl sm:text-4xl mb-3">
                                    How to Stream
                                </h3>
                                <p>
                                    Just connect your existing twitch account by going to <kbd>Settings</kbd> > <kbd>Link Twitch Account</kbd>.
                                    Reward will do the rest for you. Active Twitch streams will automatically be recognised and be redirected to reward
                                    without any more efforts on your side.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        :
            <div className="container-primary pt-32 sm:pt-42 mb-8 text-gray-100 flex justify-center items-center h-full">
                <div className="flex items-center justify-center h-20 w-20 justify-end">
                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-24 w-24"></div>
                </div>
            </div>
    )
}

export default Settings;
