import React, {useState, useEffect, Fragment} from "react";
import {withRouter} from 'react-router-dom';
import {request} from "graphql-request";
import config from "../../server/config/default";

const About = () => {
    const [description, setDescription] = useState([]);
    const [items, setItems] = useState([]);
    const [games, setGames] = useState([]);

    useEffect(() => {
        request(config.content_api, `{ abouts {description items{name description list{name}}} games(first: 9, orderBy: name_ASC) {games} }`).then(res => {
            setDescription(res.abouts[0].description);
            setItems(res.abouts[0].items);
            setGames(res.games);
        })
    }, []);

    const Item = ({item}) => (
        <div className="text-center lg:text-left">
            <h2 className="text-3xl sm:text-4xl font-bold md:leading-tight text-white">{item.name}</h2>
            <p className="mt-4 text-base font-medium text-gray-200">{item.description}</p>
            <ul className="text-gray-200 text-base mt-5 list-inside lg:list-outside">
                {item.list.map((list, index) => (
                    <li key={index}>{list.name}</li>
                ))}
            </ul>
            <a href="/register" className="btn w-fit primary mx-auto lg:mx-0 mt-6">Register now</a>
        </div>
    );

    const GameCardPlaceholder = () => (
        <div className="flex-[1_0_16%]">
            <div
                className="relative block shadow-md rounded-md bg-gray-750/50 border border-gray-750 pb-[133.333333%]"></div>
        </div>
    )

    return (
        <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                <h1 className="headline">{description}</h1>
                <div className="relative w-full mt-10 mb-20 sm:mt-16">

                    <div className="space-y-10">
                        {items.map((item, index) => (
                            index < 1 ?
                                <div key={index} className="flex flex-col lg:flex-row items-center lg:even:flex-row-reverse">
                                    <div className="flex-[1_0_100%] lg:flex-[1_0_60%] w-full hidden sm:block">
                                        {index === 0 ?
                                            <div className="relative flex lg:mr-10 mb-10 lg:mb-0 items-center overflow-hidden h-[500px] after:absolute after:inset-0 after:content-[''] overlay-shadow">
                                                <div
                                                    className="-rotate-6 relative w-full flex flex-wrap gap-5
                                                [&_:nth-child(10n+6)]:translate-x-20 [&_:nth-child(10n+7)]:translate-x-20 [&_:nth-child(10n+8)]:translate-x-20 [&_:nth-child(10n+9)]:translate-x-20 [&_:nth-child(10n+10)]:translate-x-20">
                                                    <GameCardPlaceholder/>
                                                        {games.map((game, gameIndex) => (
                                                            <Fragment key={gameIndex}>
                                                                <div className="flex-[1_0_16%]">
                                                                    <div
                                                                        className="relative block shadow-md rounded-md pb-[133.333333%]">
                                                                        <img
                                                                            src={game.games.data[0].box_art_url.replace('{width}x{height}', '285x380')}
                                                                            className="absolute object-contain object-center w-full h-full rounded-[inherit]"/>
                                                                    </div>
                                                                </div>
                                                                {(gameIndex + 1) % 3 === 0 && (gameIndex + 1) !== games.length && [...Array(2)].map((e, i) =>
                                                                    <GameCardPlaceholder key={i}/>)
                                                                }
                                                            </Fragment>
                                                        ))}
                                                    <GameCardPlaceholder/>
                                                </div>
                                            </div>
                                            : <div>

                                            </div>
                                        }
                                    </div>

                                    <div className="flex-[1_0_100%] lg:flex-[1_0_40%] lg:[&>div_.headline]:!text-left">
                                        <Item item={item}/>
                                    </div>
                                </div>
                            :
                                <div key={index} className="[&>div]:text-center [&>div_ul]:list-inside [&>div_a_button]:mx-auto">
                                    <Item item={item}/>
                                </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default About;
