import React, {useState, useEffect} from "react";
import {request} from "graphql-request";
import config from "../../server/config/default";
import axios from "axios";

const Withdraw = () => {
    const [userBalance, setUserBalance] = useState(0.);
    const [status, setStatus] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("paypal");

    const loadUserBalance = async () => {
        const res = await axios.get("/user/info");
        setUserBalance(res.data.coins / 10);
    }

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    }

    const submitWithdrawTicket = async (e) => {
        e.preventDefault();

        const payment_info = e.target["payment_info"].value;
        const withdraw_amount = e.target["withdraw_amount"].value;
        if (!payment_info || payment_info === "" || !withdraw_amount) {
            setStatus("There was a problem processing your request, please contact an admin.");
            return;
        }
        const res = await axios.post("/user/withdraw", { method: paymentMethod, info: payment_info, amount: withdraw_amount })
        setStatus(res.data.status);
    }

    useEffect(() => {
        const fetchInfo = async () => {
            await loadUserBalance();
        }

        fetchInfo().catch(console.error);
    }, []);

    return (
        <div className="container-primary pt-32 sm:pt-44">

            <div className="container-primary-content">
                <div className="px-4 w-full max-w-md sm:px-8 py-10 shadow-lg bg-gray-750/30 border-gray-750/30 border rounded-md h-full mx-4 sm:mx-0 mt-12 sm:mt-48 mb-24 sm:mb-48">
                    <h1 className="text-xl text-white mb-4">Current Balance:</h1>
                    <h1 className="headline text-center mb-6">{userBalance}€</h1>
                    {
                        userBalance > 50 ?
                            <form onSubmit={submitWithdrawTicket} method="POST" className="mb-4">
                                <select id="payment_method" name="payment_method" onChange={handlePaymentMethodChange} className="flex border-white/5 items-center justify-center w-full h-10 font-bold primary mb-2 rounded-md bg-gray-750 text-white p-2 border">
                                    <option value="paypal">Paypal</option>
                                    <option value="bank">Bank Transfer</option>
                                </select>
                                {
                                    paymentMethod === "bank" ?
                                        <input type="text" pattern="^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$" name="payment_info" id="payment_info" placeholder="IBAN" className="mb-8" required/>
                                    :
                                        <input type="email" name="payment_info" id="payment_info" placeholder="Paypal Email" className="mb-2" required/>
                                }
                                <input type="number" name="withdraw_amount" id="withdraw_amount" max={userBalance} min={1} placeholder="Amount" className="mb-8" required/>
                                <button type="submit" className="flex items-center justify-center w-full h-10 font-bold primary">
                                    Withdraw
                                </button>
                            </form>
                        :
                            <p className="text-left text-sm text-indigo-600 mt-0.5">Minimum balance required for withdrawal is 50.00€</p>
                    }
                    <p className="text-left text-sm text-indigo-600 mt-0.5">{status}</p>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;
