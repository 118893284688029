import React, {Fragment} from 'react'
import axios from 'axios';
import {Popover, Menu, Transition} from '@headlessui/react';
import {
    CogIcon,
    LogoutIcon,
    AdjustmentsIcon,
    UserIcon,
    ArrowSmLeftIcon
} from '@heroicons/react/solid';
import nav from './Navigation';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrolled: false,
            username: '',
            twitchName: "",
            coins: 0,
            isAdmin: false
        }
    }

    componentDidMount() {
        this.getUser();

        window.addEventListener("scroll", () => {
            this.setState({isScrolled: window.scrollY > 0});
        });
    }

    getUser() {
        axios.get('/user/info')
            .then(res => {
                this.setState({
                    username: res.data.username,
                    twitchName: res.data.twitchName,
                    coins: res.data.coins
                });
            }).catch(e => console.log(e.message))

        axios.get('/user/roles')
            .then(res => {
                this.setState({
                    isAdmin: res.data.includes('Admin')
                });
            }).catch(e => console.log(e.message));
    }

    dropdownClasses(active) {
        return `${active ? 'bg-gray-700 text-white' : 'text-gray-100'} transition-default group flex w-full items-center rounded-md px-2 py-2 text-base font-medium`
    }

    render() {
        const isLoggedIn = this.state.username !== undefined;

        return (
            <div
                className={`fixed top-0 z-40 w-full px-4 backdrop-blur transition-colors duration-500 sm:px-6 lg:px-8 ${this.state.isScrolled ? "border-b border-gray-750 bg-gray-900/70" : "border-gray-850 bg-none"}`}
            >
                <div
                    className="mx-auto flex max-w-7xl items-center border-gray-100 py-6 md:space-x-10">
                    <div className="flex lg:w-0 lg:flex-1">
                        <a href={'/'}
                           className="inline-flex items-center text-xl font-bold text-white">
                            <div className="mr-2 h-6 w-[35px] fill-indigo-600">
                                <reward-logo/>
                            </div>
                            Reward
                        </a>
                    </div>
                    <nav className="hidden items-center space-x-10 lg:flex">
                        {
                            nav.map((item) => (
                                item.name === 'Services & Products' ?
                                    <a key={item.name} href={item.to}
                                       className="text-base font-medium text-indigo-400 transition-default hover:text-indigo-300">
                                        {item.name}<font className="ml-1" face="Arial">&#x2728;</font>
                                    </a>
                                    :
                                    <a key={item.name} href={item.to}
                                       className="text-base font-medium text-gray-100 transition-default hover:text-white">
                                        {item.name}
                                    </a>
                            ))
                        }
                    </nav>

                    <div className="ml-auto md:items-center md:justify-end md:ml-0 md:flex md:flex-1 lg:w-0">
                        {
                            isLoggedIn ?
                                <div className="flex md:items-center">
                                    <div className="flex items-center">
                                        <span className="text-white mr-1">{this.state.coins}</span>
                                        <svg className="h-6 w-6 text-indigo-500 mr-3" fill="currentColor" width="292" height="292" viewBox="0 0 292 292" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M291.764 145.882C291.764 65.319 226.455 0 145.882 0C65.31 0 0 65.318 0 145.882C0 226.446 65.31 291.764 145.882 291.764C226.454 291.764 291.764 226.445 291.764 145.882ZM27.353 145.882C27.353 80.418 80.417 27.353 145.882 27.353C211.347 27.353 264.411 80.418 264.411 145.882C264.411 211.346 211.346 264.411 145.882 264.411C80.418 264.411 27.353 211.346 27.353 145.882ZM45.588 145.882C45.588 201.272 90.492 246.176 145.882 246.176C201.281 246.176 246.176 201.272 246.176 145.882C246.176 90.492 201.271 45.588 145.882 45.588C90.493 45.588 45.588 90.492 45.588 145.882ZM109.101 104H120.269L129.841 111.128L109.101 127.522L88.3601 111.128L97.9327 104H109.101ZM163.262 189H197.652L182.117 164.641C187.628 164.068 193.077 161.509 197.459 157.438C199.593 155.463 201.412 153.17 202.851 150.639C204.296 148.114 205.297 145.358 205.81 142.491L208.654 126.275C208.92 124.779 209.033 123.261 208.991 121.742C208.95 120.319 208.757 118.905 208.415 117.524C208.086 116.205 207.612 114.927 207.002 113.714C205.82 111.366 204.119 109.32 202.031 107.732C200.981 106.936 199.846 106.26 198.647 105.717C197.397 105.152 196.09 104.725 194.748 104.444C193.318 104.146 191.863 103.997 190.403 104H138.173L147.746 111.128L127.005 127.522H171.918C172.223 127.514 172.527 127.574 172.806 127.698C173.086 127.822 173.334 128.007 173.534 128.239C173.732 128.485 173.873 128.772 173.947 129.079C174.021 129.387 174.026 129.707 173.962 130.017L172.431 138.748C172.189 140.124 170.88 141.243 169.511 141.243H89.3854L84.2904 170.289C84.0672 171.545 83.9723 172.821 84.0073 174.096C84.0416 175.291 84.2039 176.479 84.4915 177.639C84.7675 178.747 85.1659 179.821 85.6792 180.84C86.672 182.812 88.1 184.531 89.8542 185.865C90.7361 186.534 91.6895 187.101 92.6966 187.558C94.8761 188.53 97.2375 189.021 99.6218 189H115.399L119.65 164.765H147.806L163.262 189Z"/>
                                        </svg>
                                    </div>
                                    <Menu as="div" className="relative text-left">
                                        <div>
                                            <Menu.Button as="a"
                                                         className="cursor-pointer">
                                                <img className="h-9	w-9 rounded-full"
                                                     src={'/profile_pictures/' + this.state.username + '.png'}
                                                     onError={(e) => {
                                                         e.target.src = '/profile_pictures/default.svg'
                                                     }}/>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="focus:outline-0 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-700 rounded-md bg-gray-750 shadow-lg ring-1 ring-gray-700">
                                                <div className="p-2">
                                                    {
                                                        this.state.isAdmin ?
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <a href="/dashboard"
                                                                       className={this.dropdownClasses(active)}>
                                                                        <AdjustmentsIcon
                                                                            className="mr-2 h-6 w-6 text-indigo-500"
                                                                            aria-hidden="true"/>
                                                                        Dashboard
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            : <div></div>
                                                    }
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a href={`/stream/${this.state.twitchName}`}
                                                               className={this.dropdownClasses(active)}>
                                                                <UserIcon className="mr-2 h-6 w-6 text-indigo-500"
                                                                         aria-hidden="true"/>
                                                                Profile
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a href="/settings"
                                                               className={this.dropdownClasses(active)}>
                                                                <CogIcon className="mr-2 h-6 w-6 text-indigo-500"
                                                                         aria-hidden="true"/>
                                                                Settings
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a href="/withdraw"
                                                               className={this.dropdownClasses(active)}>
                                                                <ArrowSmLeftIcon className="mr-2 h-6 w-6 text-indigo-500"
                                                                         aria-hidden="true"/>
                                                                Withdraw
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                                <div className="p-2">
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <a href="/logout" className={this.dropdownClasses(active)}>
                                                                <LogoutIcon className="mr-2 h-6 w-6 text-indigo-500"
                                                                            aria-hidden="true"/>
                                                                Logout
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                :
                                <div className="lg:flex hidden">
                                    <a href="/login">
                                        <button className="ml-4 ghost">Login</button>
                                    </a>

                                    <a href="/register">
                                        <button className="ml-4 primary">Sign up</button>
                                    </a>
                                </div>
                        }
                    </div>

                    <Popover as="div" className="ml-4 lg:hidden">
                        <Popover.Button
                            onClick={() => window.scrollTo(0, 0)}
                            className="bg-gray-800 p-2 text-gray-200 hover:bg-gray-700 hover:text-white focus:ring-inset focus:ring-indigo-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                 stroke="currentColor" aria-hidden="true" className="h-6 w-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >

                            <Popover.Panel
                                className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition-default lg:hidden">
                                <div
                                    className="divide-y divide-gray-700 rounded-lg bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="px-5 pt-5 pb-6">
                                        <div className="flex items-center justify-between">
                                            <a href="/"
                                               className="inline-flex items-center text-xl font-bold text-white">
                                                <div className="mr-2 h-6 w-[35px] fill-indigo-600">
                                                    <reward-logo/>
                                                </div>
                                                Reward
                                            </a>
                                            <div className="-mr-2">
                                                <Popover.Button
                                                    className="bg-gray-750 p-2 text-gray-200 hover:bg-gray-700 hover:text-white focus:ring-inset focus:ring-indigo-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         aria-hidden="true" className="h-6 w-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M6 18L18 6M6 6l12 12"></path>
                                                    </svg>
                                                </Popover.Button>
                                            </div>
                                        </div>

                                        <div className="mt-6">
                                            <nav className="grid gap-y-8">
                                                {nav.map((item) => (
                                                    <a key={item.name} href={item.to}
                                                       className="transition-default -m-3 flex items-center rounded-md p-3 hover:bg-gray-750 hover:shadow-sm">
                                                        {React.cloneElement(item.icon, {className: 'h-6 w-6 flex-shrink-0 text-indigo-500'})}
                                                        <span
                                                            className="ml-3 text-base font-medium text-gray-100 hover:text-white">
                                                            {item.name}
                                                        </span>
                                                    </a>))}
                                            </nav>
                                        </div>
                                    </div>
                                    {!isLoggedIn &&
                                        <div className="space-y-6 py-6 px-5">
                                            <a href="/register">
                                                <button
                                                    className="flex w-full items-center justify-center text-base primary">Sign
                                                    up
                                                </button>
                                            </a>
                                            <p className="mt-6 text-center text-base font-medium text-gray-200">
                                                Existing Account?
                                                <a href="/login"
                                                   className="ml-1 text-indigo-400 transition-default hover:text-indigo-300">
                                                    Sign in
                                                </a>
                                            </p>
                                        </div>
                                    }
                                </div>
                            </Popover.Panel>

                        </Transition>
                    </Popover>
                </div>
            </div>
        )
    }
}
