import React, {useEffect, useState} from "react";
import config from "../../server/config/default";
import {request} from "graphql-request";
import nav from './Navigation';

export const Footer = () => {
    const [rewardDescription, setRewardDescription] = useState();
    const [loaded, setLoaded] = useState();

    const [navigation, setNavigation] = useState([]);

    const subpages = {
        name: "Subpages",
        pages: nav
    }

    useEffect(() => {
        request(config.content_api, `{ namings {rewardDescription} legals {name} socialMedias {name url} }`).then(res => {
            setRewardDescription(res.namings[0].rewardDescription);

            const legals = []
            res.legals.forEach(l => legals.push({name: l.name, to: `/legal/${l.name.toLowerCase().replace(' ', '-')}`}))

            const contact = []
            res.socialMedias.forEach(s => contact.push({name: s.name, to: s.url}));

            setNavigation([subpages, {name: "Legal", pages: legals}, {name: "Contact", pages: contact}])

            setLoaded(true);
        })
    }, []);

    return (
        loaded ?
            <div className="container-primary">
                <div className="container-primary-content border-t border-gray-800 pt-16">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8 w-full">
                        <div className="xl:col-span-1">
                            <a href="/" className="inline-flex items-center text-xl font-bold text-white">
                                <div className="mr-2 h-6 w-[35px] fill-indigo-600">
                                    <reward-logo/>
                                </div>
                                Reward
                            </a>
                            <p className="mt-5 flex max-w-sm items-center text-base leading-6 text-gray-200">
                                {rewardDescription}
                            </p>
                        </div>

                        <div className="mt-12 xl:col-span-2 xl:mt-0">
                            <div className="md:grid md:gap-8"
                                 style={{gridTemplateColumns: `repeat(${navigation.length}, minmax(0, 1fr))`}}>
                                {navigation.map((nav, index) => (
                                    <div key={index} className="mt-12 md:mt-0">
                                        <h4
                                            className="text-sm font-medium uppercase leading-5 tracking-wider text-gray-300">
                                            {nav.name}
                                        </h4>
                                        <div className="mt-4 space-y-4">
                                            {nav.pages.map((page, navIndex) => (
                                                <a key={navIndex} href={page.to}
                                                   className="block transform text-base leading-6 text-gray-200 transition-default hover:text-white">
                                                    {page.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 pt-8 pb-8">
                        <p className="text-base leading-6 text-gray-300 xl:text-center">
                            © {new Date().getFullYear()} Reward. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
            : null
    );
}
