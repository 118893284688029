import React, {useState, useEffect} from "react";
import {withRouter} from 'react-router-dom';
import {CheckIcon, ArrowRightIcon, ExclamationCircleIcon, CheckCircleIcon} from '@heroicons/react/solid';
import axios from "axios";
import {request} from "graphql-request";
import config from "../../server/config/default";
import {useForm} from "react-hook-form";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

const Pricing = () => {
    const PaymentState = {
        success: 1,
        cancel: 0,
        none: -1,
    }
    const [subscriptions, setSubscriptions] = useState([]);
    const [products, setProducts] = useState([]);
    const [pricingDescription, setPricingDescription] = useState([]);
    const [planFeatures, setPlanFeatures] = useState([]);
    const [roles, setRoles] = useState([]);
    const [promoCode, setPromoCode] = useState("");

    let [state, setState] = useState(PaymentState.none);
    let [sessionId, setSessionId] = useState('');
    let [loaded, setLoaded] = useState(false);


    useEffect(() => {
        axios.get('/user/roles').then(rolesRes => {
            setRoles(rolesRes.data);
            // Get plans
            axios.get(`/stripe/plans`).then(plansRes => {
                const subs = plansRes.data.plans.subscriptions;
                const filteredSubs = subs.filter(sub => !rolesRes.data.includes(sub.name));
                setSubscriptions(filteredSubs);
                setProducts(plansRes.data.plans.products);
            });
        });

        //set cookie if this site was opened with a promo link present
        const searchParams = new URLSearchParams(location.search);
        const argFromURL = searchParams.get('promo_code');
        if (argFromURL) {
            cookies.set("promo_code", argFromURL);
        }

        //load a cookie if it is present to apply promo code
        const promo_cookie = cookies.get("promo_code");
        if (promo_cookie) {
            setPromoCode(promo_cookie);
        }

        // Check to see if this is a redirect back from checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setState(PaymentState.success);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setState(PaymentState.cancel);
        }

        // Get feature list for plan
        request(config.content_api, `{ namings {pricingDescription} products(first: 100) {stripeId features{name}} }`).then(res => {
            setPricingDescription(res.namings[0].pricingDescription);
            console.log(res)
            setLoaded(true);
        })
    }, [sessionId]);

    const getFilteredFeatures = (id) => {
        let filteredFeatures = planFeatures.filter(feature => feature.stripeId === id);
        if (filteredFeatures.length == 0) return [];
        return filteredFeatures[0].features;
    }

    if (state == PaymentState.none && loaded) {
        return <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                <h1 className="headline z-10">Subscription pricing</h1>
                <h2 className="subline z-10">{pricingDescription}</h2>
                <div className="relative w-full mt-10 sm:mt-16 mb-20">
                    <div
                        className="absolute-center opacity-20 w-2/5 h-full blur-3xl bg-gradient-to-b from-indigo-500 to-indigo-900 rounded-[30%_70%_70%_30%_/_30%_30%_70%_70%] animate-gradient"></div>
                    <div className="md:grid md:gap-8 space-y-8 md:space-y-0"
                         style={{gridTemplateColumns: `repeat(${subscriptions.length}, minmax(0, 1fr))`}}>
                        {
                            subscriptions.map((plan) => {
                                return (
                                    <form action="/stripe/create-checkout-session" className="box" method="POST"
                                          key={plan.id}>
                                        <div className="border-b border-white/5 p-6">
                                            <h2 className="text-2xl font-bold text-white">{plan.name}</h2>
                                            <p className="text-indigo-50">{plan.description}</p>

                                            <div className="mt-7 flex">
                                                <h2 className="text-5xl font-bold text-white">{plan.unit_amount / 100}€</h2>
                                                <p className="mt-auto mb-1 ml-2 text-indigo-50">/ {plan.recurring.interval}</p>
                                            </div>
                                        </div>

                                        <div className="space-y-4 p-6 py-4">
                                            {
                                                getFilteredFeatures(plan.product).map((feature, index) =>
                                                    <div key={index} className="flex text-white items-center text-base">
                                                        <CheckIcon
                                                            className="mr-2 h-5 w-5 text-indigo-500"/> {feature.name}
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div className="w-full p-6 pt-4">
                                            <input type="hidden" name="lookup_key" value={plan.id}/>
                                            <input type="hidden" name="mode" value="subscription"/>
                                            <input type="hidden" name="promo_code" value={promoCode}/>
                                            <button id="checkout-and-portal-button" className="w-full primary"
                                                    type="submit">
                                                Purchase Plan <ArrowRightIcon className="ml-2 h-5 w-5"/>
                                            </button>
                                        </div>
                                    </form>
                                )
                            })
                        }
                    </div>
                    <h3 className="z-10 mb-10 pt-20 st:py-20 text-3xl sm:text-4xl font-bold md:leading-tight text-white">Coins
                        pricing</h3>
                    <div className="md:grid md:gap-6 space-y-6 md:space-y-0 grid-cols-2 lg:grid-cols-4">
                        {
                            products.map((plan) => {
                                return (
                                    <form  className="w-full box" action="/stripe/create-checkout-session" method="POST" key={plan.id}>
                                        <div className="border-b border-white/5 p-6">
                                            <h2 className="text-2xl font-bold text-white">{plan.transform_quantity?.divide_by || 1} {plan.name}</h2>
                                            <p className="text-indigo-50">{plan.description}</p>
                                            <h2 className="text-5xl mt-7 font-bold text-white">{plan.unit_amount / 100}€</h2>
                                        </div>

                                        <div className="w-full p-6">
                                            <input type="hidden" name="lookup_key" value={plan.id}/>
                                            <input type="hidden" name="mode" value="payment"/>
                                            <input type="hidden" name="promo_code" value={promoCode}/>
                                            <button id="checkout-and-portal-button" className="w-full primary" type="submit">
                                                Purchase Coins <ArrowRightIcon className="ml-2 h-5 w-5"/>
                                            </button>
                                        </div>
                                    </form>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    } else if (state == PaymentState.success && sessionId !== '') {
        return <SuccessDisplay sessionId={sessionId}/>;
    } else if (state == PaymentState.cancel) {
        return <ErrorDisplay/>;
    } else {
        return (
            <div></div>
        )
    }
}
/*
    <form action="/stripe/create-portal-session" method="POST">
        <input type="hidden" id="session-id" name="session_id" value={sessionId}/>
        <button id="checkout-and-portal-button" type="submit">Manage your billing information</button>
    </form>
*/
const SuccessDisplay = ({sessionId}) => {
    return (
        <div className="container-primary pt-32 sm:pt-44 mb-20">
            <div className="container-primary-content">
                <div className="box p-6 md:mx-auto text-center">
                    <CheckCircleIcon className="text-green-500/80 w-16 h-16 mx-auto"/>
                    <h3 className="text-2xl font-bold text-white mt-2">Payment Successful!</h3>
                    <p className="text-gray-100 text-base mt-4 mb-6">
                        Thank you for your purchase.<br/>
                        Your purchased goods will be available shortly!
                    </p>
                    <a href="/" className="btn w-fit mx-auto primary">Go back</a>
                </div>
            </div>
        </div>
    );
};

const ErrorDisplay = () => {
    return (
        <div className="container-primary pt-32 sm:pt-44 mb-20">
            <div className="container-primary-content">
                <div className="box p-6 md:mx-auto text-center">
                    <ExclamationCircleIcon className="text-red-500/80 w-16 h-16 mx-auto"/>
                    <h3 className="text-2xl font-bold text-white mt-2">Oops!</h3>
                    <p className="text-gray-100 text-base mt-4 mb-6">
                        Your purchase was unsuccessful.<br/>
                        Keep looking, maybe you like something else!
                    </p>
                    <a href="/pricing" className="btn w-fit mx-auto primary">Go back</a>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
