import React, {useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import Challenge from "./Challenge";

const ChallengeManager = () => {
    const [challenges, setChallenges] = useState([]);
    const [history, setHistory] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [duplicationError, setDuplicationError] = useState(false);

    useEffect(() => {
        getChallenges();
        getHistory();
    }, []);

    const getChallenges = () => {
        axios.get("/dashboard/challenges")
            .then(res => {
                setChallenges(res.data);
                setLoaded(true);
            }).catch(e => console.log(e.message));
    }

    const getHistory = () => {
        axios.get("/dashboard/challenge-history")
            .then(res => {
                setHistory(res.data);
            }).catch(e => console.log(e.message));
    }

    const onSubmit = (data) => {
        console.log(data);
        setDuplicationError(false);
        axios.post("/dashboard/challenge", data)
            .then(res => {
                setLoaded(false);
                getChallenges();
            }).catch(e => {
                if (e.response.status == 400) {
                    setDuplicationError(true)
                } else {
                    console.log("challenge manager submit", e)
                }
            }
        );
    }

    const { register, handleSubmit } = useForm();
    return (
        <React.Fragment>
            <h1 className="headline text-xl !text-left sm:!text-center mb-12">Challenge Manager</h1>
            <div className="grid grid-cols-autoFill gap-10">
                <div className="flex flex-col gap-5">
                    <h2 className="text-white text-xl pb-6 sm:!text-center">Active Challenges</h2>
                    {loaded ?
                            challenges.map((challenge, idx) => {
                                return <Challenge key={idx} challengeObj={challenge} reloadCallback={getChallenges}></Challenge>
                            })
                        :
                            [...Array(3)].map((e, i) =>
                                <div key={i} className="h-10 relative block shadow-md rounded-md bg-gray-750/50 border border-gray-750 animate-skeleton"></div>
                            )
                    }
                    <hr/>
                    {
                        duplicationError ?
                            <small className="form-text text-indigo-600">Challenge already exists!</small>
                        :
                            <></>
                    }
                    <form className="flex flex-col gap-5" onSubmit={ handleSubmit(onSubmit) }>
                        <label> Header:
                            <input {...register("header")} type="text" name="header" required/>
                        </label>
                        <label> Description:
                            <input {...register("description")} type="text" name="description" required/>
                        </label>
                        <label> Start Date:
                            <input {...register("start_timestamp")} min={new Date().toISOString().slice(0, -8)} id="date" type="datetime-local" required/>
                        </label>
                        <label> Duration:
                            <input {...register("duration")} min={new Date().toISOString().slice(0, -8)} id="date" type="time" required/>
                        </label>
                        <label> Join price:
                            <input {...register("price")} id="price" type="number" min={0} required/>
                        </label>
                        <button className="primary" type="submit">Create Challenge</button>
                    </form>
                </div>
                <div>
                    <h2 className="text-white text-xl pb-6 sm:!text-center">Challenge History</h2>
                    {
                        history.map((challenge, idx) => {
                            return <Challenge key={idx} challengeObj={challenge} reloadCallback={getHistory}></Challenge>
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChallengeManager;