import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import ChallengeManager from "../components/challenges/ChallengeManager";

const Dashboard = () => {
    return (
        <React.Fragment>
            <div className="container-primary pt-32 sm:pt-44 text-gray-100">
                <div className="container-primary-content">
                    <ChallengeManager/>
                </div>
            </div>
        </React.Fragment>
    )
}


export default withRouter(Dashboard);