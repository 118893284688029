import React from "react";
import Homepage from './pages/Homepage';
import Settings from './pages/Settings';
import Pricing from "./pages/Pricing";
import About from "./pages/About";

import GameCategory from './pages/GameCategory';
import ChallengeCategory from './pages/ChallengeCategory';
import Legal from './pages/Legal';

import { BrowserRouter as Switch, Route } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import FAQ from "./pages/FAQ";
import Stream from "./pages/Stream";
import UpcomingChallenges from "./pages/UpcomingChallenges";
import Tournament from "./pages/Tournament";
import Withdraw from "./pages/Withdraw";
import Poll from "./pages/Poll";
import TwitchEmbedWrapper from "./components/twitch/TwitchThumbnailRef";

export default class Root extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <Switch className="flex flex-grow">
                <div className="flex-grow">
                    <Route exact path="/" render={props => (
                        <Homepage  {...props} />
                    )}/>

                    <Route exact path="/stream/:channel" render={(props) => (
                        <Stream {...props}/>
                    )}/>

                    <Route exact path="/settings" render={props => (
                        <Settings {...props} />
                    )}/>

                    <Route path="/pricing">
                        <Pricing />
                    </Route>

                    <Route path="/about">
                        <About />
                    </Route>

                    <Route path="/faq">
                        <FAQ />
                    </Route>

                    <Route path="/tournament">
                        <Tournament />
                    </Route>

                    <Route exact path="/category/:game" render={(props) => (
                        <GameCategory {...props}/>
                    )}/>

                    <Route exact path="/challenge/:challenge" render={(props) => (
                        <ChallengeCategory {...props}/>
                    )}/>

                    <Route exact path="/legal/:legal" render={(props) => (
                        <Legal {...props}/>
                    )}/>

                    <Route exact path="/poll/:pollName" render={(props) => (
                        <Poll {...props}/>
                    )}/>

                    <Route path="/dashboard">
                        <Dashboard />
                    </Route>

                    <Route path="/withdraw">
                        <Withdraw />
                    </Route>

                    <Route path="/upcoming">
                        <UpcomingChallenges />
                    </Route>
                </div>
            </Switch>
        )
    }
}
