import React from 'react'

import {
    ChatIcon,
    CreditCardIcon,
    CubeIcon,
    InformationCircleIcon,
    QuestionMarkCircleIcon,
    FireIcon
} from "@heroicons/react/solid";

const nav = [
    {name: 'Upcoming Challenges', to: '/upcoming', icon: <FireIcon/>},
    {name: 'Pricing', to: '/pricing', icon: <CreditCardIcon/>},
    {name: 'About', to: '/about', icon: <InformationCircleIcon/>},
    {name: 'FAQ', to: '/faq', icon: <QuestionMarkCircleIcon/>},
    {name: 'Tournament', to: '/tournament', icon: <QuestionMarkCircleIcon/>},
];

export default nav;
