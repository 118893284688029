import React, {useState, useEffect} from "react";
import VideoPlayer from "../components/VideoPlayer";
import {TwitchEmbed} from "react-twitch-embed";

const Stream = (props) => {
    const CHANNEL = props.match.params.channel;

    return (
        <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                <div className="w-full mb-20 rounded-xl aspect-video">
                    <TwitchEmbed
                        channel={CHANNEL}
                        darkMode={true}
                        onAuthenticate={function noRefCheck(){}}
                        onVideoPause={function noRefCheck(){}}
                        onVideoPlay={function noRefCheck(){}}
                        onVideoReady={function noRefCheck(){}}
                        width="100%"
                        height="100%"
                        withChat={false}
                    />
                    <div className="flex items-center p-2.5 pb-0">
                        <p className="text-white font-semibold text-xl">{CHANNEL}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stream;
