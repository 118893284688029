import React from 'react';
import {request} from "graphql-request";
import config from "../../server/config/default";

export default class Legal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ""
        }
    }

    routeName() {
        return this.props.match.params.legal.replace(/\b./g, (a) => {
            return a.toUpperCase().replace('-', ' ');
        });
    }

    componentDidMount() {
        request(config.content_api, `{ legal(where: {name: "${this.routeName()}"}) {content{html}} }`).then(res => {
            this.setState({content: res.legal.content.html})
        })
    }

    render() {
        return (
            <div className="container-primary pt-32 sm:pt-44">
                <div className="container-primary-content">
                    <h1 className="headline">{this.routeName()}</h1>
                    <div className="relative w-full mt-10 mb-20 sm:mt-16 rich-text"
                         dangerouslySetInnerHTML={{__html: this.state.content}}></div>
                </div>
            </div>
        )
    }
}
