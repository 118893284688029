import React, {useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/solid'

const Challenge = (props) => {
    const [challenge, setChallenge] = useState(props.challengeObj);

    const [winnerObj, setWinnerObj] = useState(null);

    useEffect(() => {
        if (challenge.winner != "") {
            loadWinner();
        }
    }, [])

    const loadWinner = () => {
        axios.get("/dashboard/user", { params: { name: challenge.winner }})
            .then(res => {
                if (res.data) {
                    setWinnerObj(res.data);
                }
            });
    }

    const onDelete = () => {
        axios.delete("/dashboard/challenge", { data: { id: challenge.id } }).then(res => {
            props.reloadCallback();
        });
    }

    const { register, handleSubmit } = useForm();
    return (
        <React.Fragment>
            <div className="shadow-md rounded-md bg-gray-750/50 border border-gray-750 p-5">
                <div className="flex flex-row gap-5 justify-between items-center">
                    <h2 className="text-white font-bold">{challenge.header}</h2>
                    <button onClick={onDelete}>
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <p className="text-indigo-50">
                    {challenge.description}
                </p>

                {
                    winnerObj ?
                        <p className="pt-2">
                            <span className="text-indigo-500 font-semibold">Winner:</span> {winnerObj.email}
                        </p>
                   :
                       <></>
                }
            </div>
        </React.Fragment>
    )
}

export default Challenge;