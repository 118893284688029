import React, {useState, useEffect} from "react";
import {request} from "graphql-request";
import config from "../../server/config/default";

const Tournament = () => {
    const [content, setContent] = useState("");

    const loadContent = async () => {
        const result = await request(config.content_api, `{ legal(where: {name: "Tournament"}) {content{html}} }`);
        setContent(result.legal.content.html)
    }

    useEffect(() => {
        const fetchInfo = async () => {
            await loadContent();
        }

        fetchInfo().catch(console.error);
    }, []);

    return (
        <div className="container-primary pt-32 sm:pt-44">
            <div className="container-primary-content">
                <div className="relative w-full mt-10 mb-20 sm:mt-16 rich-text" dangerouslySetInnerHTML={{__html: content}}></div>
                <div className="flex items-center justify-center">
                    <a href="https://www.rewardgamers.com/event-info/ea-sports-fc-24">
                        <button className="ml-4 primary">See prices</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Tournament;
