import React, {useState, useEffect} from "react";
import {request} from 'graphql-request';
import {UserGroupIcon} from '@heroicons/react/solid';
import config from '../../server/config/default';
import axios from "axios";
import 'regenerator-runtime/runtime'

export const Games = () => {
    const [games, setGames] = useState(null);
    const [contentLoaded, setContentLoaded] = useState(false);

    const loadLivestreams = async () => {
        const result = await axios.get('/streams/', { params: { limit: 20 }});
        return result.data;
    }

    const loadStreamedGames = async (activeStreams) => {
        const result = await request(config.content_api, `{ games(first: 40, orderBy: name_ASC) {games} }`);
        const updatedGames = result.games.map(gameEntry => {
            const game = gameEntry.games.data[0];
            const playerCount = activeStreams.filter(streamInstance => streamInstance?.stream.game_name === game.name).length;
            return { ...game, playerCount };
        });
        setGames(updatedGames);
    }

    useEffect(() => {
        const fetchInfo = async () => {
            const activeStreams = await loadLivestreams();
            await loadStreamedGames(activeStreams);

            setContentLoaded(true);
        }

        fetchInfo().catch(console.error);
    }, []);

    return (
        contentLoaded ?
            <div className="relative w-full flex flex-wrap gap-6 -mb-6">
                {games.sort((a, b) => b.playerCount - a.playerCount).map((game, index) => (
                    <div key={index} className="flex-[1_0_30%] md:flex-[1_0_22%] lg:flex-[1_0_18%] xl:flex-[1_0_12%]">
                        <a href={`/category/${encodeURIComponent(game.name)}`} className="relative block shadow-md rounded-md transition-default group pb-[133.333333%] hover:scale-105">
                            <div className="absolute opacity-0 inset-0 bg-white shadow-[0_0_15px_1px_theme(colors.orange.200)] rounded-[inherit] transition-default group-hover:opacity-100 group-hover:inset-[-3px] group-hover:transition-none"></div>
                            <div className="overflow-hidden rounded-[inherit]">
                                <img src={game.box_art_url.replace('{width}x{height}', '285x380')}
                                     className="absolute object-contain	object-center w-full h-full rounded-[inherit]"/>
                                <div className="absolute inset-0 transition-default rounded-[inherit] overflow-hidden shine"></div>
                                <div className="absolute bg-gray-900/70 opacity-0 bottom-2.5 inset-x-2.5 translate-y-5 transition-default rounded-md shadow-md py-1 px-2.5 backdrop-blur group-hover:opacity-100 group-hover:translate-y-0">
                                    <div className="translate-y-4 text-center transition-default duration-300 text-white font-bold group-hover:translate-y-0 break-words">
                                        {game.name}
                                    </div>
                                </div>
                                <div className="absolute rounded-md shadow-md backdrop-blur bg-gray-900/70 top-2.5 right-2.5 py-0.5 px-2.5 flex items-center">
                                    <UserGroupIcon className="h-4 w-4 mr-1 text-indigo-500"/>
                                    <span className="text-sm text-white font-bold">{game.playerCount}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
                {[...Array(7)].map((e, i) => <div key={i}
                    className="flex-[1_0_30%] md:flex-[1_0_22%] lg:flex-[1_0_18%] xl:flex-[1_0_12%]"></div>) /* Placeholders */}
            </div>
            : <div className="relative w-full flex flex-wrap gap-6">
                {[...Array(21)].map((e, i) =>
                <div key={i} className="flex-[1_0_30%] md:flex-[1_0_22%] lg:flex-[1_0_18%] xl:flex-[1_0_12%]">
                    <div className="relative block shadow-md rounded-md bg-gray-750/50 border border-gray-750 animate-skeleton pb-[133.333333%]"></div>
                </div>
                )}
            </div>
    );
}
