import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

const ChallengeCategory = (props) => {
    const [loaded, setLoaded] = useState(false);

    const [visible, setVisible] = useState(12);
    const [livestreams, setLivestreams] = useState([]);
    const [challenge, setChallenge] = useState({});

    const challenge_id = decodeURIComponent(props.match.params.challenge);

    const loadChallengeStreams = useCallback(async () => {
        const challengeStreams = await axios.get('/streams/challenge', { params: { limit: 30, challengeId: challenge_id } });
        console.log("Streams: ", challengeStreams.data)
        setLivestreams(challengeStreams.data);
    }, []);


    useEffect(() => {
        const fetchInfo = async () => {
            await loadChallengeStreams();

            setLoaded(true);
        }

        fetchInfo().catch(console.error);
    }, []);

    return (
        <React.Fragment>
            <div className="container-primary pt-32 sm:pt-44 text-gray-100">
                <div className="container-primary-content">
                    {
                        loaded ?
                            <>
                                <h1 className="headline">{challenge.header}</h1>
                                <p className="subline">{challenge.description}</p>
                                <div className="relative w-full mt-10 sm:mt-16 mb-20">
                                    {
                                        !challenge.winner ?
                                            <div className="flex flex-wrap gap-6 -mb-6 justify-center">
                                                {livestreams.slice(0, visible).map((entry, index) =>
                                                    <a key={index} href={`/stream/${entry.stream.user_name}`} className="flex-auto sm:flex-[1_0_33%] lg:flex-[1_0_24%] xl:flex-[1_0_19%] box transition-default hover:scale-105">
                                                        <img className='rounded-t-[inherit]' src={entry.stream.thumbnail_url.replace("{width}", "640").replace("{height}", "360")}/>
                                                        <div className="flex items-center p-2">
                                                            <img className="h-8	w-8 mr-2 rounded-full"
                                                                 src={`/profile_pictures/${entry.account.username}.png`}
                                                                 onError={(e) => {
                                                                     e.target.src = '/profile_pictures/default.svg'
                                                                 }}/>
                                                            <p className="text-gray-200 text-sm">{entry.account.username}</p>
                                                        </div>
                                                    </a>
                                                )}
                                                {
                                                    [...Array(4)].map((e, i) => <div key={i} className="flex-[1_0_100%] md:flex-[1_0_33%]"></div>)
                                                }
                                            </div>
                                        :
                                            <div>
                                                <h1 className="text-3xl sm:text-4xl font-bold md:leading-tight text-white text-center">
                                                    🎉 User <a className="text-indigo-400 transition-default hover:text-indigo-300" href={`/streams/${challenge.winner}`}>{challenge.winner}</a> has won the challenge! 🎉
                                                </h1>
                                            </div>
                                    }
                                    {
                                        livestreams.length === 0 ?
                                            <div className="flex flex-col items-center">
                                                <h2 className="text-xl font-medium text-gray-200 md:text-2xl">
                                                    No streams available ☹
                                                </h2>
                                                <a href="/">
                                                    <button className="primary mt-10">Go back</button>
                                                </a>
                                            </div>
                                        : null
                                    }
                                </div>
                            </>
                            :
                            <div className="container-primary pt-32 sm:pt-42 mb-8 text-gray-100 flex justify-center items-center h-full">
                                <div className="flex items-center justify-center h-20 w-20 justify-end">
                                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-24 w-24"></div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default ChallengeCategory;
