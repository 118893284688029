const config = {
    host: process.env.NODE_ENV === 'production' ? `https://${process.env.HOST}` : `http://${process.env.LOCALHOST}`,
    content_api: process.env.CONTENT_API,
    server: {
        secret: process.env.SERVER_SECRET,
        port: process.env.SERVER_PORT
    },
    stripe: {
        secret: process.env.STRIPE_SECRET,
        public: process.env.STRIPE_PUBLIC,
        webhook: process.env.STRIPE_WEBHOOK,
    },
    database: process.env.NODE_ENV === 'production' ? `mongodb://${process.env.LOCALHOST}/${process.env.DATABASE}` : `mongodb://${process.env.LOCALHOST}/${process.env.DATABASE}`,
    rtmp_server: {
        auth: {
            secret: "gugugagagagag",
        },
        rtmp: {
            port: process.env.RTMP_PORT,
            chunk_size: 60000,
            gop_cache: true,
            ping: 60,
            ping_timeout: 30
        },
        http: {
            port: process.env.HTTP_PORT,
            mediaroot: './server/media',
            allow_origin: '*'
        },
        https: {
            port: 8443,
            key: './server/nyxnation.com.key',
            cert:'./server/nyxnation.com.pem',
        },
        trans: {
            ffmpeg: process.env.NODE_ENV === 'production' ? process.env.FFMPEG_LINUX : process.env.FFMPEG_WIN,
            tasks: [
                {
                    app: 'live',
                    hls: true,
                    hlsFlags: '[hls_time=2:hls_list_size=3:hls_flags=delete_segments]',
                    dash: true,
                    dashFlags: '[f=dash:window_size=3:extra_window_size=5]'
                }
            ]
        },
	    host: process.env.NODE_ENV === 'production' ? `https://rtmp.${process.env.HOST}` : `http://${process.env.LOCALHOST}:${process.env.HTTP_PORT}`,
        rtmp_host: process.env.NODE_ENV === 'production' ? `rtmp://${process.env.HOST_IP}:${process.env.RTMP_PORT}` : `rtmp://${process.env.LOCALHOST}:${process.env.RTMP_PORT}`
    }
};

module.exports = config;
