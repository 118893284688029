import React, {useEffect, useState} from 'react';
import {request} from "graphql-request";
import config from "../../../server/config/default";

const TwitchThumbnailRef = ({ channelData, clickable = true }) => {
    const getThumbnail = () => channelData["thumbnail_url"].replace("{width}", "640").replace("{height}", "360")

    return (
        <div>
            {
                channelData != null ?
                    <div className='rounded-t-[inherit]'>
                        {
                            clickable ?
                                <a  key={channelData.user_name} href={`/stream/${channelData.user_name}`} className="border-none flex-auto sm:flex-[1_0_33%] lg:flex-[1_0_24%] xl:flex-[1_0_19%] box transition-default hover:scale-105">
                                    <img className='rounded-t-[inherit] border-none' src={getThumbnail()}/>
                                    <div className="flex items-center p-2">
                                        <p className="text-gray-200 text-sm">{channelData.user_name}</p>
                                    </div>
                                </a>
                            :
                                <div className="border-none flex-auto sm:flex-[1_0_33%] lg:flex-[1_0_24%] xl:flex-[1_0_19%] box transition-default hover:scale-105">
                                    <img className='rounded-t-[inherit] border-none' src={getThumbnail()}/>
                                    <div className="flex items-center p-2">
                                        <p className="text-gray-200 text-sm">{channelData.user_name}</p>
                                    </div>
                                </div>
                        }
                    </div>
                :
                    <div></div>
            }
        </div>
    );
};

export default TwitchThumbnailRef;