import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './assets/scss/style.scss';

import config from '../server/config/default';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Root from './Root.js';
import Navbar from "./components/Navbar";
import {Footer} from "./components/Footer";

const stripePromise = loadStripe(config.stripe.public);

const logo = document.createElement("script");
logo.src = "/logo.js";
document.body.appendChild(logo);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <div className="flex flex-col min-h-screen">
            <Elements stripe={stripePromise}>
                <Navbar />
                <div className="flex flex-grow">
                    <Root />
                </div>
                <Footer />
            </Elements>
        </div>
    </BrowserRouter>
);
