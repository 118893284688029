import React, {useState, useEffect} from "react";
import moment from "moment";
import axios from "axios";
import Modal from "react-modal";

const UpcomingChallenges = () => {
    const [challenges, setChallenges] = useState([]);
    const [rulesModalOpen, setRulesModalOpen] = useState(false);
    const [idOfRules, setIdOfRules] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const isChallengeInPast = (challenge) => {
        let endDate = moment(challenge.endTime);
        let currentDate = moment();
        return currentDate.isAfter(endDate);
    }

    useEffect(() => {
        Modal.setAppElement("#root")
        axios.get("/dashboard/challenges").then(res => {
            setChallenges(res.data.filter(c => !isChallengeInPast(c)));
            setLoaded(true);
        }).catch(e => console.log("challenges", e));
    }, [])

    const getChallengeBoxArt = (challenge) => {
        let gameData = challenge.game.games.data;
        if (gameData.length > 0) {
            return gameData[0].box_art_url.replace("{width}", 285).replace("{height}", 380);
        } else {
            return "https://static-cdn.jtvnw.net/ttv-boxart/497057-285x380.jpg";
        }
    }

    const dateRounder = (time) => {
        const now = new Date().getTime();
        const distance = time - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        let message = "";

        if (minutes > 0) {
            message = minutes + " minutes"
        }

        if (hours > 0) {
            message = hours + " hours"
        }

        if (days > 0) {
            message = days + " days"
        }

        return message;
    }

    const isChallengeRunning = (challenge) => {
        let startDate = moment(challenge.startTime);
        let currentDate = moment();
        return currentDate.isAfter(startDate);
    }

    const closeRulesModal = () => {
        setRulesModalOpen(false);
    }

    const openRulesModal = (challengeIdx) => {
        console.log(challengeIdx);
        console.log(challenges.filter(challenge => !isChallengeRunning(challenge)))
        setIdOfRules(challengeIdx);
        setRulesModalOpen(true);
    }

    return (
        loaded ?
            <div className="container-primary pt-32 sm:pt-44">
                <div className="container-primary-content">
                    <h1 className="headline">Upcoming Challenges</h1>
                    <div className="relative w-full mt-10 mb-20 sm:mt-16">
                        <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 xl:gap-6">
                            {
                                challenges.filter(challenge => !isChallengeRunning(challenge)).map((challenge, index) => (
                                    <div key={index} className="flex h-full w-full flex-col items-start p-6 text-left box">
                                        <div className="w-full flex items-center">
                                            <img src={getChallengeBoxArt(challenge)} className="h-14 rounded-sm shadow-sm w-auto mr-2 object-contain"/>
                                            <div>
                                                <h3 className="mb-auto text-lg font-bold text-white first:text-indigo-500 lg:text-base xl:text-lg">{challenge.challengeTitle}</h3>
                                                <p className="text-gray-100">🏆 {challenge.reward} {challenge.rewardType == "Coins" ? "Coins" : "€"} 🏆</p>
                                            </div>
                                            <p className="ml-auto text-sm font-normal text-gray-100 text-center !rounded-md box px-2.5 py-1.5">{challenge.joinPrice} Coins Required</p>
                                        </div>

                                        <p className="mt-2 mb-auto text-base text-gray-100 xl:text-[15px]">{challenge.description}</p>

                                        <hr className="w-full border-t border-white/5 my-6"/>

                                        <div className="w-full flex items-center justify-between mb-2">
                                            <p className="text-white">Difficulty:</p>
                                            <p className="text-gray-100">{challenge.difficulty}</p>
                                        </div>

                                        <div className="w-full flex items-center justify-between mb-2">
                                            <p className="text-white">Plattform:</p>
                                            <div className="flex gap-1.5">
                                                {
                                                    challenge.platform.map(name => (
                                                        <div key={name} className="text-sm font-normal text-gray-100 text-center border border-white/5 rounded-md px-2.5 py-1">
                                                            { name }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        {
                                            isChallengeRunning(challenge) ?
                                                <></>
                                                :
                                                <div className="w-full">
                                                    <div className="w-full flex items-center justify-between">
                                                        <p className="text-white">Start countdown:</p>
                                                        <div className="tooltip">
                                                            <span className="text-gray-100">{dateRounder(Date.now() + (new Date(challenge.startTime) - Date.now()))}</span>
                                                            <p>{moment(new Date(challenge.startTime)).format('lll')}</p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-6 sm:mt-8 w-full flex justify-end items-center">
                                                        <button className="btn ghost mr-2" onClick={() => openRulesModal(index)}>
                                                            View Rules
                                                        </button>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                ))
                            }
                            <Modal
                                isOpen={rulesModalOpen}
                                onRequestClose={closeRulesModal}
                                contentLabel="Example Modal"
                                className="absolute bg-gray-750 rounded-lg z-40 top-1/2 left-1/2 right-auto bottom-auto mr-[-50%] translate-x-[-50%] translate-y-[-50%] w-[420px]"
                                style={{
                                    overlay: {
                                        backgroundColor: "rgb(19 22 31 / 0.7)",
                                        zIndex: 10
                                    }
                                }}
                            >
                                <div className="flex h-full w-full flex-col items-start p-6 text-left z-40">
                                    {
                                        challenges.filter(challenge => !isChallengeRunning(challenge))[idOfRules].rules.length > 0 ?
                                            challenges.filter(challenge => !isChallengeRunning(challenge))[idOfRules].rules.map((rule, index) => (
                                                <p className="text-white" key={index}>{index+1}. {rule.name}</p>
                                            ))
                                            :
                                            <p className="text-white">No Rules defined.</p>
                                    }
                                    <div className="flex justify-center items-center w-full mt-5">
                                        <button className="btn ghost mr-2" onClick={closeRulesModal}>Back</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className="container-primary pt-32 sm:pt-42 mb-8 text-gray-100 flex justify-center items-center h-full">
                <div className="flex items-center justify-center h-20 w-20 justify-end">
                    <div className="animate-spin rounded-full border-t-2 border-blue-500 border-opacity-25 h-24 w-24"></div>
                </div>
            </div>
    );
}

export default UpcomingChallenges;
